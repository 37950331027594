import React from "react"
import {Link} from "gatsby"
import {forgetPassword, BrandforgetPassword} from "../services/auth"

class ForgetPasswordForm extends React.Component {
    state = {
        email: ``, error: ``, errorspinner: ``, success: ``, type: ``
    }

    constructor(props) {
        super(props);
    }

    componentDidMount(props) {
        this.setState({
            type: this.props.type,
        })
    }

    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSubmit = async event => {
        event.preventDefault()
        this.setState((state, props) => {
            return {
                errorspinner: true,
            };
        });

        if (this.state.type === "user") {
            var lgres = await forgetPassword(this.state)
        }
        if (this.state.type === "brand") {
            var lgres = await BrandforgetPassword(this.state)
        }
        if (lgres === false) {
            this.setState((state, props) => {
                return {email: state.email, error: "Email not found in our list"};
            });
            setTimeout(() => {
                this.setState({
                    errorspinner: '', error: '',
                });
            }, 1500);

        } else {
            this.setState((state, props) => {
                return {errorspinner: '', error: '', success: "Email send to your registered account follow other steps to reset password"};
            });
        }
    }

    render() {
        return (<>
            <h1>Forget Password</h1>
            {this.state.error !== "" && <div className="err alert alert-danger">{this.state.error}</div>}
            {this.state.success !== "" && <div className="err alert alert-success">{this.state.success}</div>}
            <form method="post" onSubmit={this.handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Enter email address. you will receive email for the further procedure</label>
                    <input type="text" name="email" className="form-control" onChange={this.handleUpdate}/>
                </div>
                <div className="mb-3">
                    <button type="submit" className="btn btn-primary">
                        Generate Link
                        {this.state.errorspinner !== "" &&
                            <div className="spinner-border spinner-bordersignup" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                    </button>
                </div>
            </form>
            {this.state.type === 'user' && <p className="text-center">Back to <Link to="/login">Login </Link></p>}
            {this.state.type === 'brand' && <p className="text-center">Back to <Link to="/brand-login">Login </Link></p>}
        </>)
    }
}

export default ForgetPasswordForm